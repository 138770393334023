// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  END_POINT: "https://api9.aktivu.com/",
  // END_POINT: "http://localhost:3001/",
  // CLIENT_END_POINT: "aktivutestsso.auth.us-east-2.amazoncognito.com",
 // redirectSignIn: "http://localhost:4200/login/ssologin",
 // redirectSignOut: "http://localhost:4200/logout/",
  CLIENT_END_POINT: "aktivucyberarkdemo.auth.us-east-2.amazoncognito.com",
  redirectSignIn: "https://aktivu.com/login/ssologin",
  redirectSignOut: "https://aktivu.com/logout",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
