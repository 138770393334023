<div class="login_new_bg">
  <div class="container custom_login_container">
    <div class="card mb-0">
      <div class="card-body p-0">
        <div class="row m-0">
          <div class="col-sm-12 col-md-6 p-0 hide_mobile_carousel">
            <div class="card_left_login">
              <div class="row m-0">
                <div class="col-sm-12">
                  <div class="text-center">
                    <img src="../../assets/img/login-check.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="row m-0 display_login_content">
                <div class="col-sm-12">
                  <h5>Welcome</h5>
                  <p>to Pycube’s aktivu<sup>TM</sup> HTM Suite!.</p>
                  <p>Enter your login credentials to monitor your assets!</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 p-0 align-self-center">
            <div>
              <div class="login_div">
                <div class="text-center">
                  <img
                    src="../../assets/img/logo.png"
                    class="login_logo"
                    alt=""
                  />
                  <h5 class="login_text">Please sign in to your account.</h5>
                </div>
                <hr />
                <div id="successMsg" *ngIf="message">
                  <span class="{{ messageColor }}">{{ message }}</span>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label
                        for="exampleFormControlInput1"
                        class="cust_form_lbl"
                        >Email address</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label
                        for="exampleFormControlInput1"
                        class="cust_form_lbl"
                        >Password</label
                      >
                      <div class="input-group">
                        <input
                          class="form-control"
                          id="exampleFormControlInput2"
                          placeholder="Enter Password"
                        />
                        <div class="input-group-append">
                          <span
                            class="input-group-text"
                            id="basic-addon2"
                            (click)="fieldTextType = !fieldTextType"
                            ><i
                              class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !fieldTextType,
                                'fa-eye': fieldTextType
                              }"
                            ></i
                          ></span>
                          <!-- <span class="input-group-text" id="basic-addon2" *ngIf="fieldTextType" (click)="fieldTextType = false"><i
                                class="fa fa-eye-slash"></i></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="mt-1" />
                <div class="text-right">
                  <button type="button" class="btn btn-link">
                    Reset Password
                  </button>
                  <button type="submit" class="btn btn-primary">Login</button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="margin-left: 1%"
                  >
                    Sign in with SSO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
