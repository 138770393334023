<nz-layout class="common-layout">
    <app-side-bar *ngIf="show"></app-side-bar>
     <nz-layout class="right-layout">
       <app-header class="header-main"></app-header>
       <div class="page-container">
         <div class="main-content" [ngClass]="isCollapsed ? 'unFold' : 'isFold'">
       <nz-content>
         <div class="inner-content">
           <router-outlet></router-outlet>
           <button type="button" class="btn btn-sm btn-info custom-toast-clear" hidden label="Clear">Clear</button>
           <!-- <p-toast position="top-right" key="myTopLeft"></p-toast> -->
         </div>
       </nz-content>
     </div>
       <app-footer></app-footer>
       
     </div>
     </nz-layout>
   </nz-layout>