import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { ButtonModule } from "primeng/button";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./login/login.component";
import { SsoLoginComponent } from "./sso-login/sso-login.component";
const antdModule = [NzButtonModule, NzAvatarModule, NzDropDownModule];

@NgModule({
  declarations: [LoginComponent, SsoLoginComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    ...antdModule,
  ],
})
export class AuthModule {}
