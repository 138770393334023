import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public httpOptions = null;

  constructor(private http: HttpClient) { }

  post(data, rte): Observable<any> {
    return this.http.post(`${rte}`, data);
  }

  get(rte): Observable<any> {
    return this.http.get(`${rte}`);
  }

  getWithParams(rte, options): Observable<any> {
    this.httpOptions = {
      params: options
    };
    return this.http.get(`${rte}`, this.httpOptions);
  }

  put(data, rte): Observable<any> {
    return this.http.put(`${rte}`, data);
  }

  delete(rte): Observable<any> {
    return this.http.delete(`${rte}`);
  }

  deleteWithParams(rte, options): Observable<any> {
    this.httpOptions = {
      params: options
    };
    return this.http.delete(`${rte}`, this.httpOptions);
  }

  deleteWithData(data, rte): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(`${rte}`, options);
  }


  sendAuthtokenPost(data: any, url: any): Observable<any> {
    let authtoken = "";
    if (localStorage.getItem("authtoken") && localStorage.getItem("authtoken") != "") {
      authtoken = localStorage.getItem("authtoken")
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: authtoken
      })
    };

    return this.http.post(url, data, this.httpOptions);
  }

}
