import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { IncidetTicketService } from './incidet-ticket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private incdntTcktSrvc : IncidetTicketService) { }

  getToken() {
    return localStorage.getItem('accesstoken')
  }

  loggedIn() {
    return !!localStorage.getItem('accesstoken')
  }


  logoutUser() {
    console.log("sravani")
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('timer');
    this.router.navigate(['']);
  }

  requestReset(data): Observable<any> {
    return this.api.post(data, "users/forgotpassword");
  }

  createNewPassword(data): Observable<any> {
    return this.api.post(data, "users/createpassword")
  }
}
