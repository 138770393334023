<nz-layout class="common-layout">
    <nz-layout class="right-layout">
        <app-config-header class="header-main" style="margin-left: 0px;"></app-config-header>
        <div class="page-container">
            <div class="main-content">
                <nz-content>
                    <div class="inner-content">
                        <section class="content">
                            <div class="container-fluid">
                                <div class="card" style="padding: 1.2rem;">
                                    <p-steps [model]="items"></p-steps>
                                </div>
                            </div>
                        </section>
                        <router-outlet></router-outlet>
                    </div>
                </nz-content>
            </div>
            <app-footer></app-footer>

        </div>
    </nz-layout>
</nz-layout>