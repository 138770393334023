import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ApiService } from "src/app/providers/api.service";
import { CognitoService } from "src/app/providers/cognito.service";
import { EncrDecrService } from "src/app/providers/encrdecr.service";
import { LoginService } from "src/app/providers/login.service";
import { secret_key } from "src/app/_helpers/global-veriables";
import { MustMatch } from "src/app/_helpers/must-match.validator";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  messageColor;
  message = "";
  fieldTextType: boolean = true;
  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  IsvalidForm = true;
  showLoginForm = true;
  showForgotForm = false;
  isSubmit: boolean = false;
  loginForm: FormGroup;

  isLoginClicked: boolean = false;

  userID;
  ResponseResetForm: FormGroup;
  showPasswordForm = false;
  submitted = false;

  verifyTokenForm: FormGroup;
  istokenSubmit = false;
  showVerifyTokenForm = false;

  password_pattern =
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}";
  isLoginClickedSSO: boolean = false;

  censorWord(str) {
    return str.substr(0, 3) + "*".repeat(str.length - 2) + str.slice(-1);
  }

  censorEmail(email) {
    var arr = email.split("@");
    return this.censorWord(arr[0]) + "@" + arr[1];
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loginApi: LoginService,
    private EncrDecr: EncrDecrService,
    private apiSrvc: ApiService,
    private messageService: MessageService,
    private cognitoService: CognitoService
  ) {}

  ngOnInit(): void {
    this.ResponseResetForm = this.formBuilder.group(
      {
        user_token: ["", [Validators.required]],
        password: [
          "",
          [Validators.required, Validators.pattern(this.password_pattern)],
        ],
        confirmpassword: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "confirmpassword"),
      }
    );
    sessionStorage.clear();
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });

    this.hideshow("login");

    //forgot password
    this.RequestResetForm = this.formBuilder.group({
      login_username: ["", [Validators.required, Validators.email]],
    });

    //verifyTokenForm
    this.verifyTokenForm = this.formBuilder.group({
      user_token: ["", [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.ResponseResetForm.controls;
  }
  get loginf() {
    return this.loginForm.controls;
  }

  getMenuLst(id) {
    this.router.navigate(["dashboard"]);
    let postData = {};
    this.loginApi.login(postData).subscribe(
      (response) => {
        console.log("response received");
        console.log(response);
      },
      (error) => {
        //Error callback
        console.error("error caught in component");
        console.log(error);

        throw error;
      }
    );
  }
  login() {
    this.isSubmit = true;
    this.isLoginClicked = true;
    if (this.isSubmit && this.loginForm.valid) {
      this.isSubmit = false;
      let postData = {
        login_username: this.loginForm.controls.email.value,
        login_password: this.EncrDecr.setEncrypt(
          secret_key,
          this.loginForm.controls.password.value
        ),
        //login_password: this.loginForm.controls.password.value
      };
      this.loginApi.login(postData).subscribe(
        (result) => {
          console.log(result);
          if (result.success) {
            if (result.userData.two_factor_auth === 1) {
              this.messageColor = "text-success";
              this.message =
                "We have sent Verification code to " +
                this.censorEmail(this.loginForm.controls.email.value);
              this.loginForm.reset();
              this.isLoginClicked = false;
              setTimeout(() => {
                this.message = null;
                localStorage.setItem("authtoken", result.authtoken);
                //this.router.navigate(['verify-user']);
                this.hideshow("verifyToken");
              }, 5000);
            } else {
              localStorage.setItem("accesstoken", result.accesstoken);
              localStorage.setItem("dateFormat", result.userData.date_format);
              localStorage.setItem(
                "userFullName",
                result?.userData?.user_fullname
              );
              localStorage.setItem(
                "userLoginCount",
                result?.userData?.login_count
              );

              // const permissions = [];
              //   result.userData.user_roles.forEach(element=>{
              //       permissions.push(element.role_sno);
              //   });
              //  // console.log(permissions);
              //   localStorage.setItem("roles",JSON.stringify(permissions));
              //this.permissionsService.loadPermissions(permissions);
              // for all users
              this.messageService.add({
                key: "myTopLeft",
                severity: "success",
                summary: "Response Message",
                detail: "You are successfully logged in",
              });
              if (
                result.userData.user_isConfg === 0 &&
                result.userData.user_type === 4
              ) {
                this.router.navigate(["configuration/step1"]);
              } else {
                let rte = `auth/menus`;
                this.apiSrvc.get(rte).subscribe((res) => {
                  console.log(res);
                  this.isLoginClicked = false;
                  if (res.success) {
                    if (res.menuLst[0].mnu_itm_url) {
                      this.router.navigate([res.menuLst[0].mnu_itm_url]);
                    } else {
                      this.router.navigate([
                        res.menuLst[0].children[0].mnu_itm_url,
                      ]);
                    }
                  } else {
                    this.router.navigate(["organization/user/profile"]);
                  }
                });
              }
            }
          } else {
            this.isLoginClicked = false;
            // this.showErr = true;
            this.messageColor = "text-danger";
            //this.message = "Invalid Username or Password";
            this.message = result.message;
          }
        },
        (err) => {
          this.isLoginClicked = false;
          this.messageColor = "text-danger";
          // this.showErr = true;
          if (err.name && err.name == "HttpErrorResponse") {
            this.message =
              "Trouble in authentication server.  Please contact administrator";
          } else {
            this.message = err;
          }
        }
      );
    } else {
      this.isLoginClicked = false;
      console.log("testing");
      return;
    }
  }

  ResetPassword() {
    console.log("rest");
    this.submitted = true;
    if (this.ResponseResetForm.invalid) {
      return;
    } else {
      this.submitted = false;
      console.log(this.ResponseResetForm.value);
      let postData = {
        user_token: this.ResponseResetForm.controls.user_token.value,
        password: this.EncrDecr.setEncrypt(
          secret_key,
          this.ResponseResetForm.controls.password.value
        ),
        confirmpassword: this.EncrDecr.setEncrypt(
          secret_key,
          this.ResponseResetForm.controls.confirmpassword.value
        ),
      };
      this.loginApi.createNewPassword(postData).subscribe(
        (data) => {
          if (data.success) {
            this.ResponseResetForm.reset();
            this.messageColor = "text-success";
            this.message = data.message;
            localStorage.clear();
            setTimeout(() => {
              this.message = "";
              this.hideshow("login");
            }, 5000);
          } else {
            this.messageColor = "text-danger";
            this.message = data.message;
          }
        },
        (err) => {
          this.messageColor = "text-danger";
          this.message = "Please try again after some time";
        }
      );
    }
  }

  RequestResetUser() {
    if (this.RequestResetForm.valid) {
      this.IsvalidForm = true;
      localStorage.clear();
      this.loginApi.requestReset(this.RequestResetForm.value).subscribe(
        (data) => {
          if (data.success) {
            this.messageColor = "text-success";
            this.message = data?.message; //"Reset password OTP has been sent to " + this.censorEmail(this.RequestResetForm.controls.login_username.value);
            this.RequestResetForm.reset();
            // localStorage.setItem('userID', data.user);
            /*
            localStorage.setItem('authtoken', data.authtoken);
            setTimeout(() => {
              this.message = null;
              this.messageColor = '';
              this.hideshow('createPassword');
            }, 5000);
            */
          } else {
            this.messageColor = "text-danger";
            this.message = data.message;
          }
        },
        (err) => {
          if (err.error.message) {
            this.messageColor = "text-danger";
            this.message = err;
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }

  verifyTokenSubmit() {
    this.istokenSubmit = true;
    if (this.istokenSubmit && this.verifyTokenForm.valid) {
      let postData = {
        user_token: this.verifyTokenForm.controls.user_token.value,
        activity: "two_factor_auth",
      };

      this.loginApi.multiFactor(postData).subscribe(
        (result) => {
          if (result.success) {
            // const permissions = [];
            // result.userData.user_roles.forEach(element=>{
            //     permissions.push(element.role_sno);
            // });
            // localStorage.setItem("roles",JSON.stringify(permissions));
            // this.permissionsService.loadPermissions(permissions);
            localStorage.setItem("accesstoken", result.accesstoken);
            localStorage.setItem("dateFormat", result.userData.date_format);
            localStorage.setItem(
              "userFullName",
              result?.userData?.user_fullname
            );
            localStorage.removeItem("authtoken");
            // this.router.navigate(["dashboard"]);
            if (
              result.userData.user_isConfg === 0 &&
              result.userData.user_type === 4
            ) {
              this.router.navigate(["configuration/step1"]);
            } else {
              let rte = `auth/menus`;
              this.apiSrvc.get(rte).subscribe((res) => {
                console.log(res);
                this.isLoginClicked = false;
                if (res.success) {
                  if (res.menuLst[0].mnu_itm_url) {
                    this.router.navigate([res.menuLst[0].mnu_itm_url]);
                  } else {
                    this.router.navigate([
                      res.menuLst[0].children[0].mnu_itm_url,
                    ]);
                  }
                } else {
                  this.router.navigate(["organization/user/profile"]);
                }
              });
            }
          } else {
            this.messageColor = "text-danger";
            this.message = "Invalid OTPs";
          }
        },
        (err) => {
          this.messageColor = "text-danger";
          if (err.name && err.name == "HttpErrorResponse") {
            this.message =
              "Trouble in authentication server.  Please contact administrator";
          } else {
            this.message = err;
          }
        }
      );
    }
  }

  resendOTP() {
    let tokenData = {
      activity: "two_factor_auth",
    };
    this.loginApi.resendToken(tokenData).subscribe(
      (result) => {
        if (result.success) {
          this.messageColor = "text-success";
          this.message =
            "We have sent Verification code to your registered email id.";
        } else {
          this.messageColor = "text-danger";
          this.message = "Please try again.";
        }
      },
      (err) => {
        this.messageColor = "text-danger";
        if (err.name && err.name == "HttpErrorResponse") {
          this.message =
            "Trouble in resend token.  Please contact administrator.";
        } else {
          this.message = err;
        }
      }
    );
  }

  // Form divs hide and show
  hideshow(type: any) {
    this.loginForm.reset();
    //this.RequestResetForm.reset();
    this.ResponseResetForm.reset();
    this.message = "";
    if (type == "rest") {
      this.showLoginForm = false;
      this.showForgotForm = true;
      this.showPasswordForm = false;
      this.showVerifyTokenForm = false;
    } else if (type == "login") {
      this.showLoginForm = true;
      this.showForgotForm = false;
      this.showPasswordForm = false;
      this.showVerifyTokenForm = false;
    } else if (type == "createPassword") {
      this.showLoginForm = false;
      this.showForgotForm = false;
      this.showPasswordForm = true;
      this.showVerifyTokenForm = false;
    } else if (type == "verifyToken") {
      this.showLoginForm = false;
      this.showForgotForm = false;
      this.showPasswordForm = false;
      this.showVerifyTokenForm = true;
    }
  }
  public signIn(): void {
    console.log("in sso login");
    this.cognitoService
      .federatedSignIn()
      .then(() => {
        console.log("yufyut");
      })
      .catch(() => {
        console.log("in catch");
        this.isLoginClickedSSO = false;
      });
    // this.router.navigate(["/ssologin"]);
  }
}
