import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

export interface User{
  user_sno;
  user_fullname;
  user_firstname;
  user_middlename;
  user_lastname;
  user_email;
  user_mobile;
  user_landline;
  user_profile_image;
  user_created_name;
  user_created_date;
  user_modified_name;
  user_modified_date;
  user_id;
  manager;
  user_alternate_mobile;
  designation;
  user_status;
  last_logged_in;
};

export interface UserStatus{
  user_status_id;
  user_status_name;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private api: ApiService,  private http: HttpClient) { }


  getUsersList() {
    let url =  "organization/user";
    return this.api.get(url);

  }
  //Both active and inactive users
  getAll(params:any = {}) {
    let url =  "organization/user/all";
    return this.api.getWithParams(url,params);

  }
  createUsers(data): Observable<any> {
    return this.api.post(data, "organization/user");
  }

  changeUserStatus(data): Observable<any> {
    return this.api.post(data,'organization/user/changestatus');
  }

  getUserById(login_id) {
    let url = "organization/user/" + login_id;
    console.log(url)
    return this.api.get(url);
  }

  updateUser(user_sno, data): Observable<any> {
    return this.api.put(data, 'organization/user/' + user_sno);
  }

  //users/changepassword (POST)

  changepassword(data): Observable<any> {
    return this.api.put(data,'organization/user/password/changepassword');
  }

  deleteUser(user_sno):Observable<any>{
    return this.api.delete("organization/user/"+user_sno)
  }

  getUserDependenciesById(user_sno) {
    let url = "organization/user/dependency/" + user_sno;
    return this.api.get(url);
  }
  
  getAllRoles() {
    let url = "organization/user/all_roles";
    return this.api.get(url);
  }
  dependencyUserlist(params:any = {}) {
    let url =  "dependency/user";
    return this.api.getWithParams(url,params);

  }

  dependencyBothUserContacts(parmas:any ={}){
    let url="dependency/usercontact";
    return this.api.getWithParams(url,parmas)
  }

  //Finish Configuration 

  updateUserConfigStep(login_id,user_isconfg){
    let data ={};
    let url ="organization/user/user/confg/"+login_id+'?user_isconfg='+user_isconfg;
    return this.api.put(data,url)
  }
}

