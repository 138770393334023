<div class="login_new_bg">
  <div class="container custom_login_container">
    <div class="card mb-0">
      <div class="card-body p-0">
        <div class="row m-0">
          <div class="col-sm-12 col-md-6 p-0 hide_mobile_carousel">
            <div class="card_left_login">
              <div class="row m-0">
                <div class="col-sm-12">
                  <div class="text-center">
                    <img src="../../assets/img/login-check.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="row m-0 display_login_content">
                <div class="col-sm-12">
                  <h5>Welcome</h5>
                  <p>to Pycube’s aktivu<sup>TM</sup> HTM Suite!.</p>
                  <p>Enter your login credentials to monitor your assets!</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 p-0 align-self-center">
            <div *ngIf="showLoginForm">
              <form [formGroup]="loginForm">
                <div class="login_div">
                  <div class="text-center">
                    <img
                      src="../../assets/img/logo.png"
                      class="login_logo"
                      alt=""
                    />
                    <h5 class="login_text">Please sign in to your account.</h5>
                  </div>
                  <hr />
                  <div id="successMsg" *ngIf="message">
                    <span class="{{ messageColor }}">{{ message }}</span>
                  </div>
                  <!-- <div class="alert alert-danger alert-dismissible fade show" *ngIf="showErr">
                                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        {{ message }}
                                      </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          for="exampleFormControlInput1"
                          class="cust_form_lbl"
                          >Email address</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          formControlName="email"
                          placeholder="Enter Email"
                        />
                        <span
                          class="errorlbl"
                          *ngIf="
                            isSubmit &&
                            loginForm.controls.email.errors &&
                            loginForm.controls.email.errors.required
                          "
                        >
                          Email is required
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          for="exampleFormControlInput1"
                          class="cust_form_lbl"
                          >Password</label
                        >
                        <div class="input-group">
                          <input
                            [type]="fieldTextType ? 'password' : 'text'"
                            class="form-control"
                            id="exampleFormControlInput2"
                            formControlName="password"
                            placeholder="Enter Password"
                          />
                          <div class="input-group-append">
                            <span
                              class="input-group-text"
                              id="basic-addon2"
                              (click)="fieldTextType = !fieldTextType"
                              ><i
                                class="fa"
                                [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                }"
                              ></i
                            ></span>
                            <!-- <span class="input-group-text" id="basic-addon2" *ngIf="fieldTextType" (click)="fieldTextType = false"><i
                                class="fa fa-eye-slash"></i></span> -->
                          </div>
                        </div>
                        <span
                          class="errorlbl"
                          *ngIf="
                            isSubmit &&
                            loginForm.controls.password.errors &&
                            loginForm.controls.password.errors.required
                          "
                        >
                          Password is required
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-1" />
                  <div class="text-right">
                    <button
                      type="button"
                      class="btn btn-link"
                      (click)="hideshow('rest')"
                    >
                      Reset Password
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      [disabled]="isLoginClicked"
                      (click)="login()"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="isLoginClicked"
                      ></span>
                      Login
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      (click)="signIn()"
                      style="margin-left: 1%"
                    >
                      Sign in with SSO
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="login_div forgot_div" *ngIf="showForgotForm">
              <div class="text-center">
                <img
                  src="../../assets/img/logo.png"
                  class="login_logo"
                  alt=""
                />
                <h5 class="login_text">Reset your password</h5>
              </div>
              <hr class="login_hr" />
              <div id="successMsg" *ngIf="message">
                <span class="{{ messageColor }}">{{ message }}</span>
              </div>
              <form
                action=""
                [formGroup]="RequestResetForm"
                (ngSubmit)="RequestResetUser()"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label
                        for="exampleFormControlInput1"
                        class="cust_form_lbl"
                        >Email address</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        formControlName="login_username"
                        id="exampleFormControlInput1"
                        placeholder="Enter Email"
                      />
                      <span
                        *ngIf="
                          !RequestResetForm.get('login_username').valid &&
                          !IsvalidForm
                        "
                        class="help-block text-danger"
                        >Please enter a valid email!</span
                      >
                    </div>
                  </div>
                </div>
                <hr class="mt-1" />
                <div class="text-right">
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="hideshow('login')"
                  >
                    Back to Login
                  </button>
                  <button type="submit" class="btn btn-primary active">
                    Reset Password
                  </button>
                </div>
              </form>
            </div>

            <div class="login_div forgot_div" *ngIf="showPasswordForm">
              <div class="text-center">
                <img
                  src="../../assets/img/logo.png"
                  class="login_logo"
                  alt=""
                />
                <h5 class="login_text">Set New Password</h5>
              </div>
              <hr class="login_hr" />
              <div id="successMsg" *ngIf="message">
                <span class="{{ messageColor }}">{{ message }}</span>
              </div>
              <form
                action=""
                [formGroup]="ResponseResetForm"
                (ngSubmit)="ResetPassword()"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Token</label>
                      <input
                        type="password"
                        formControlName="user_token"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f.user_token.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.user_token.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.user_token.errors.required">
                          Token is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        formControlName="password"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f.password.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.password.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.password.errors.required">
                          Password is required
                        </div>
                        <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                        <div *ngIf="f.password.errors.pattern">
                          Minimum eight characters, at least one uppercase
                          letter, one lowercase letter, one number and one
                          special character
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        formControlName="confirmpassword"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': submitted && f.confirmpassword.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.confirmpassword.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.confirmpassword.errors.required">
                          Confirm Password is required
                        </div>
                        <div *ngIf="f.confirmpassword.errors.mustMatch">
                          Passwords must match
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="mt-1" />
                <div class="text-right">
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="hideshow('login')"
                  >
                    Back to Login
                  </button>
                  <button type="submit" class="btn btn-primary active">
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div class="login_div forgot_div" *ngIf="showVerifyTokenForm">
              <div class="text-center">
                <img
                  src="../../assets/img/logo.png"
                  class="login_logo"
                  alt=""
                />
                <h5 class="login_text">
                  Please enter One Time Password(OTP) to login into your
                  account.
                </h5>
              </div>
              <hr />
              <div id="successMsg" *ngIf="message">
                <span class="{{ messageColor }}">{{ message }}</span>
              </div>
              <form
                [formGroup]="verifyTokenForm"
                (submit)="verifyTokenSubmit()"
              >
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="OTP"
                    formControlName="user_token"
                    [ngClass]="{
                      'is-invalid':
                        istokenSubmit &&
                        verifyTokenForm.controls.user_token.errors
                    }"
                  />
                  <div
                    *ngIf="
                      istokenSubmit &&
                      verifyTokenForm.controls.user_token.errors
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        verifyTokenForm.controls.user_token.errors.required
                      "
                    >
                      OTP is required
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary btn-block">
                      CONFIRM
                    </button>
                  </div>
                  <!-- /.col -->
                </div>
                <div class="row m-0">
                  <div class="col-sm-6 p-0">
                    <button
                      type="button"
                      class="btn btn-link text-dark mt-2 pl-0"
                      [routerLink]="['/']"
                    >
                      BackToLogin
                    </button>
                  </div>
                  <div class="col-sm-6 p-0">
                    <div class="text-right">
                      <button
                        type="button"
                        class="btn btn-link text-info mt-2 pr-0"
                        (click)="resendOTP()"
                      >
                        RESEND OTP
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
