import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { IconsProviderModule } from 'src/app/icons-provider.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { ConfigHeaderComponent } from './config-header/config-header.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, SideBarComponent, ConfigHeaderComponent],
  imports: [
    CommonModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    RouterModule,
    NzDropDownModule,
    NzAvatarModule
    ],
  exports:[
    HeaderComponent, 
    FooterComponent, 
    SideBarComponent,
    ConfigHeaderComponent,
    PerfectScrollbarModule
  ]
})
export class ThemeModule { }
