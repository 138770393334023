<nz-header class="header">
  
    <div class="hedaer-wrap" style="position: absolute;
  right: 206px;
  top: -5px;
  ">
      <ul class="top-menu-ul">
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" style="position: fixed;">
            <i class="fas fa-user-circle"></i> {{ getUserFullName() }}
          </a>
          <div class="dropdown-menu dropdown-menu-right p-0">
            <!-- <a class="dropdown-item" *ngIf="user_isConfg === 0 " [routerLink]="'/organization/user/profile'" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}">
              <i class="fas fa-user mr-2"></i> Profile
            </a> -->
            <a class="dropdown-item" *ngIf="loginId === 1" [routerLink]="'/organization/user/settings'" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-cogs"></i> Settings
          </a>
            <!-- <a class="dropdown-item" [routerLink]="'/organization/user/changepassword'" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}">
              <i class="fas fa-lock mr-2"></i> Change Password
            </a> -->
            <div class="dropdown-divider m-0"></div>
            <a class="dropdown-item" (click)="logout()" [routerLinkActive]="['active']"
              [routerLinkActiveOptions]="{exact:true}">
              <i class="fas fa-sign-out-alt mr-2"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  
  </nz-header>
  