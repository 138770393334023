import { environment } from "src/environments/environment";

export const congnitoConfig = {
  cognito: {
    // userPoolId: "us-east-2_VZfpoObMI",
    // userPoolWebClientId: "44iehk2i26sr4jqgo9f33733ap",
    // idp_identifier: "aktivussotestazure",
    // oauth: {
    //   domain: "aktivutestsso.auth.us-east-2.amazoncognito.com",
    //   scope: ["email", "aws.cognito.signin.user.admin", "openid"],
    //   redirectSignIn: environment.redirectSignIn,
    //   redirectSignOut: environment.redirectSignOut,
    //   region: "us-east-2",
    //   responseType: "code",
    //   idp_identifier: "aktivussotestazure",
    // userPoolId: "us-east-2_B26ahsraO",
    // userPoolWebClientId: "6q7an15422k1u52bpnpqd4jh8s",
    // idp_identifier: "testaktivucyberark",
    // oauth: {
    //   domain: "aktivutestcyberark.auth.us-east-2.amazoncognito.com",
    //   scope: ["email", "aws.cognito.signin.user.admin", "openid"],
    //   redirectSignIn: environment.redirectSignIn,
    //   redirectSignOut: environment.redirectSignOut,
    //   region: "us-east-2",
    //   responseType: "code",
    //   idp_identifier: "testaktivucyberark",
    // },
    userPoolId: "us-east-2_ZJF28Yx8J",
    userPoolWebClientId: "5vaaoc591as4l34u5pkf6q02ie",
    idp_identifier: "aktivucyberarkdemo",
    oauth: {
      domain: "aktivucyberarkdemo.auth.us-east-2.amazoncognito.com",
      scope: ["email", "aws.cognito.signin.user.admin", "openid"],
      redirectSignIn: environment.redirectSignIn,
      redirectSignOut: environment.redirectSignOut,
      region: "us-east-2",
      responseType: "code",
      idp_identifier: "aktivucyberarkdemo",
    },
  },
};
