import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-ui',
  templateUrl: './new-ui.component.html',
  styleUrls: ['./new-ui.component.scss']
})
export class NewUIComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href='http://54.193.234.187/';
  }

}
