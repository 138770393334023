import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private api: ApiService, private router: Router) {}

  login(data: any): Observable<any> {
    return this.api.post(data, "auth/login");
  }

  loginSSO(data: any): Observable<any> {
    return this.api.post(data, "auth/ssologin");
  }

  logout(data: any): Observable<any> {
    return this.api.post(data, "auth/logout");
  }

  requestReset(data): Observable<any> {
    return this.api.post(data, "auth/forgot_password");
  }

  createNewPassword(data): Observable<any> {
    return this.api.sendAuthtokenPost(data, "auth/create_password");
  }

  multiFactor(data: any): Observable<any> {
    return this.api.sendAuthtokenPost(data, "auth/verify_token");
  }

  resendToken(data: any): Observable<any> {
    return this.api.sendAuthtokenPost(data, "auth/resend_token");
  }

  logoutUser() {
    localStorage.clear();
    localStorage.removeItem("accesstoken");
    this.router.navigate([""]);
  }

  verifytoken() {
    let url = "users/verifytoken ";
    return this.api.get(url);
  }

  getAuditLogsWithParams(params: any) {
    let url = "audit";
    return this.api.getWithParams(url, params);
  }

  getAuditLogs(data: any): Observable<any> {
    return this.api.post(data, "auditlogs");
  }

  roleMatch(allowedRoles) {
    var isMatch = false;
    var userRoles: string[] = JSON.parse(localStorage.getItem("userRoles"));
    allowedRoles.forEach((element) => {
      if (userRoles.indexOf(element) > -1) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }
  verifyToken(data: any): Observable<any> {
    return this.api.getWithParams("auth/verify_token", data);
  }
  changePassword(data: any): Observable<any> {
    return this.api.post(data, "auth/change_password");
  }
}
