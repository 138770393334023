import { Component, HostListener } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ThemeService } from 'src/theme/services/theme.service';

@Component({
    selector: 'app-configure-layout',
    templateUrl: './configure-layout.component.html',
    styleUrls:['./configure-layout.component.scss']
})

export class ConfigureLayoutComponent  {
    items: MenuItem[];
    constructor(private themeService: ThemeService,private messageService: MessageService) { }
    ngOnInit(): void {
        this.items = [{
            label: 'Facility',
            routerLink: 'configuration/step1'
          },
          {
            label: 'Roles',
            routerLink: 'configuration/step2'
          },
          {
            label: 'Users',
            routerLink: 'configuration/step3'
          },
          ];
          console.log(this.items)
      }

      ngOnDestroy() {
      }
    

}
