import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ApiService } from "src/app/providers/api.service";
import { CognitoService } from "src/app/providers/cognito.service";
import { LoginService } from "src/app/providers/login.service";

@Component({
  selector: "app-sso-login",
  templateUrl: "./sso-login.component.html",
  styleUrls: ["./sso-login.component.scss"],
})
export class SsoLoginComponent implements OnInit {
  loading: boolean;
  isAuth: any = false;
  user: any;
  userData: any;
  showLoader: boolean = true;
  email: any;
  messageColor;
  message = "";
  fieldTextType: boolean = true;
  forbiddenEmails: any;
  IsvalidForm = true;
  showLoginForm = true;
  showForgotForm = false;
  isSubmit: boolean = false;

  isLoginClicked: boolean = false;

  userID;
  showPasswordForm = false;
  submitted = false;

  istokenSubmit = false;
  showVerifyTokenForm = false;

  password_pattern =
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}";
  isLoginClickedSSO: boolean = false;

  censorWord(str) {
    return str.substr(0, 3) + "*".repeat(str.length - 2) + str.slice(-1);
  }

  censorEmail(email) {
    var arr = email.split("@");
    return this.censorWord(arr[0]) + "@" + arr[1];
  }

  constructor(
    private loginApi: LoginService,
    private cognitoService: CognitoService,
    private router: Router,
    private messageService: MessageService,
    private apiSrvc: ApiService
  ) {
    console.log("im in");
    this.cognitoService
      .getUser()
      .then((res) => {
        console.log(res);
        if (res == null) {
          this.messageService.add({
            key: "myTopLeft",
            severity: "error",
            summary: "Response Message",
            detail: "Login failed!",
          });
          this.showLoader = false;
          this.router.navigate(["/login"]);
        } else {
          this.email = res?.attributes?.email || "";
          if (this.email == "") {
            this.messageService.add({
              key: "myTopLeft",
              severity: "error",
              summary: "Response Message",
              detail: "Login failed!",
            });
            this.showLoader = false;
            // this.router.navigate(["/login"]);
          } else {
            console.log(res);
            this.userData = res;
            this.login();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.showLoader = false;
        this.router.navigate(["/login"]);
      });
  }

  login(): void {
    this.loginApi.loginSSO(this.userData).subscribe(
      (result) => {
        if (result.success) {
          localStorage.setItem("accesstoken", result.accesstoken);
          localStorage.setItem("ssousername", this.userData?.username);
          localStorage.setItem("dateFormat", result.userData.date_format);
          const permissions = [];

          // for all users
          let rte = `auth/menus`;
          this.apiSrvc.get(rte).subscribe((res) => {
            if (res.success) {
              if (res.menuLst[0].mnu_itm_url) {
                this.router.navigate([res.menuLst[0].mnu_itm_url]);
              } else {
                this.router.navigate([res.menuLst[0].children[0].mnu_itm_url]);
              }
            } else {
              this.router.navigate(["organization/user/profile"]);
            }
          });
          //this.router.navigate(["dashboard"]);
        } else {
          this.showLoader = false;
          this.messageService.add({
            key: "myTopLeft",
            severity: "error",
            summary: "Response Message",
            detail: "Invalid Username or Password",
          });
          setTimeout(() => {
            this.router.navigate(["login"]);
          }, 3000);
        }
      },
      (err) => {
        let message: any;
        if (err.name && err.name == "HttpErrorResponse") {
          message =
            "Trouble in authentication server.  Please contact administrator";
        } else {
          message = err;
        }
        this.showLoader = false;
        this.messageService.add({
          key: "myTopLeft",
          severity: "error",
          summary: "Response Message",
          detail: JSON.stringify(message),
        });
        setTimeout(() => {
          this.router.navigate(["login"]);
        }, 3000);
      }
    );
  }

  ngOnInit(): void {
    console.log("im in");
  }
}
