import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { ThemeService } from '../services/theme.service';
import { filter } from 'rxjs/operators';
import { IncidetTicketService } from 'src/app/providers/incidet-ticket.service';
import * as introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  introJS = introJs('body');
  introSteps = [];
  isCollapsed: boolean;
  mnuLst: any;
  url: string;
  groupBy: any;
  userLoginCount:number;
  constructor(private themeService: ThemeService, private apiSrvc: ApiService, private router: Router, private incdntTcktSrvc: IncidetTicketService,
  ) {
    this.userLoginCount = localStorage.getItem("userLoginCount")?parseInt(localStorage.getItem("userLoginCount")):0;
    this.introJS.setOptions({
      steps: []
    });
    let elem = document.getElementById('btnFileModalClose') as HTMLButtonElement;
    if (elem) {
      elem.click();
    }
    
    this.mnuLst = this.incdntTcktSrvc.getMenusData();

    if (!this.mnuLst) {
      this.getMnulstItms();
    } else {
      //this.getMnulstItms();
    }
  }
  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isCollapsed => this.isCollapsed = isCollapsed);
    this.url = this.router.url;
    if (this.mnuLst) {

      for (let i = 0; i < this.mnuLst.length; i++) {
        this.mnuLst[i]['openActive'] = false;

        if (!this.mnuLst[i].children) {
          this.mnuLst[i].children = [];
        }
        for (let m = 0; m < this.mnuLst[i].children.length; m++) {
          this.mnuLst[i].children[m]['submenu'] = [];
          if (this.url == this.mnuLst[i].children[m]['mnu_itm_url']) {
            this.mnuLst[i]['openActive'] = true;
          }

        }
      }      
    }

  }

  getMnulstItms() {
    this.introSteps = [{
      title:"Hi there!",
      intro: 'Welcome to Aktivu Asset Management'
    }];
    let rte = `auth/menus`
    this.apiSrvc.get(rte).subscribe((res) => {
      if (res.success) {
        for (let i = 0; i < res.menuLst.length; i++) {
          res.menuLst[i]['openActive'] = false;
          if (!res.menuLst[i].children) {
            res.menuLst[i].children = [];
          }
          for (let m = 0; m < res.menuLst[i].children.length; m++) {
            res.menuLst[i].children[m]['submenu'] = [];
            if (this.url == res.menuLst[i].children[m]['mnu_itm_url']) {
              res.menuLst[i]['openActive'] = true;
            }

          }
        }

        this.incdntTcktSrvc.setmenusData(res.menuLst);

        this.mnuLst = res.menuLst;
        
        setTimeout(() => {
          this.mnuLst.forEach(element => {
            if (element?.children?.length > 0) {
              this.introSteps.push({
                element: document.getElementById("menu_" + element.parent_menu),
                intro: element?.prnt_mnu_itm_desc,
                position: 'right',
                title: element.prnt_mnu_itm_name
              });
              // element.children.forEach(element1 => {
              //   console.log("element1", element1);
              //   this.introSteps.push({
              //     element: document.getElementById("menu_" + element1.mnu_itm_sno),
              //     intro: element1.mnu_itm_name + ':<p>' + element1?.mnu_description + '</p>',
              //     position: 'bottom'
              //   });
              // });
            } else {
              this.introSteps.push({
                element: document.getElementById("menu_" + element.mnu_itm_sno),
                intro: element?.mnu_description,
                position: 'right',
                title: element.mnu_itm_name
              });
            }
          });
          window.scrollTo(0, 0);
          this.introJS.setOptions({
            scrollToElement: true,
            showProgress: true,
            showBullets: false,
            steps: this.introSteps
          });
          this.introJS.onbeforechange(function (targetElement) {
            if (targetElement.className === 'introjsFloatingElement') {
              window.scrollTo(0, 0)
            }
          })
          
          if(this.userLoginCount == 0){
            this.userLoginCount++;
            localStorage.setItem("userLoginCount", this.userLoginCount.toString() );
            this.introJS.refresh().start();
          }
        }, 5000);

        //setTimeout(this.introJS.refresh().start(), 5000);          
      }
    })
  }
}

