import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private api: ApiService, private http: HttpClient) { }

  getProfile() {
    let url = "profile";
    return this.api.get(url);
  }

  changePassword(data): Observable<any> {
    return this.api.put(data, "profile/changepassword");
  }

  updateProfile(data) {
    return this.api.put(data, "profile");
  }

}
