import { Component, OnInit } from '@angular/core';
import { IncidetTicketService } from 'src/app/providers/incidet-ticket.service';
import { LoginService } from 'src/app/providers/login.service';
import { ProfileService } from 'src/app/providers/profile.service';
import { ThemeService } from '../services/theme.service';
import  jwt_decode from 'jwt-decode';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isCollapsed: boolean;
  isExpand: boolean;
  loginId: any;
  token_decoded: any;
  userFullName: any;
  constructor(
    private themeService: ThemeService,
    private loginApi: LoginService,
    private profileApi: ProfileService,
    private incdntTcktSrvc: IncidetTicketService
  ) {
    this.userFullName = localStorage.getItem("userFullName");
    this.getProfile();
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isCollapsed) => (this.isCollapsed = isCollapsed)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.token_decoded = jwt_decode(localStorage.getItem("accesstoken"));
    this.loginId = this.token_decoded.login_id;
    console.log(this.loginId);
  }
  toggleFold() {
    this.isCollapsed = !this.isCollapsed;
    this.themeService.toggleFold(this.isCollapsed);
  }
  toggleExpand() {
    this.isCollapsed = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isCollapsed);
  }

  logout() {
    console.log("logout");
    this.loginApi.logout({}).subscribe(
      (result) => {
        console.log(result);
      },
      (err) => {
        console.log(err);
      }
    );
    sessionStorage.clear();
    localStorage.clear();
    this.loginApi.logoutUser();
    this.incdntTcktSrvc.setmenusData("");
  }
  getProfile() {
    this.profileApi.getProfile().subscribe(
      (result) => {
        if (result.success) {
          this.userFullName = result.profileData?.user_fullname;
          localStorage.setItem("userFullName", this.userFullName);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getUserFullName() {
    if (typeof localStorage["userFullName"] === "undefined") {
      return "";
    } else {
      return localStorage.getItem("userFullName");
    }
  }
}
