<nz-layout  data-html2canvas-ignore="true">
  <nz-layout>
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" class="side-nav">
      <div class="logo" id="logo">
        <img src="../../assets/img/aktivu_Logo.png" style="width: 100%;    padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 16px;">
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline" class="sider-menu">
        <div *ngFor="let item of mnuLst; let i = index">
          <li nz-submenu [ngClass]="[item.children.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item']" [nzIcon]="item.prnt_mnu_itm_nzicon" [nzTitle]="(item.prnt_mnu_itm_name)"
            *ngIf="item.children.length > 0" [nzOpen]="item.openActive" [routerLinkActive]="item.children.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
            style="margin-top: 0px !important;border-bottom: 1px dotted #909294;"
            id="menu_{{item.parent_menu}}">
            <ul>
              <li *ngFor="let sub of item.children"
                [ngClass]="sub.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                [routerLinkActive]="sub.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
                [routerLinkActiveOptions]="{exact: true, __change_detection_hack__: 1}" class="ant-menu-submenu-title" nz-menu-item>
                <a class="ant-menu-submenu-title" [routerLink]="sub.mnu_itm_url" *ngIf="sub.submenu.length > 0">
                 {{sub.mnu_itm_name}}
                  </a>
                  <a [routerLink]="sub.mnu_itm_url" *ngIf="sub.submenu.length === 0" 
                      routerLinkActive="ant-menu-item-selected"
                      id="menu_{{sub.mnu_itm_sno}}">
                  <span>{{sub.mnu_itm_name}}</span>
              </a>
            </li>
            </ul>
          </li>
          <li nz-menu-item *ngIf="item.children.length == 0" routerLinkActive="ant-menu-item-selected"
            id="menu_{{item.mnu_itm_sno}}"
            [routerLinkActiveOptions]="{exact: true}"            
            style="margin-top: 0px !important;border-bottom: 1px dotted #909294;" [routerLink]="item.mnu_itm_url">
            <a [routerLink]="item.mnu_itm_url" *ngIf="item.children.length === 0">
            <i nz-icon [nzType]="item.mnu_itm_nzicon"></i>
            <span>{{item.mnu_itm_name}}</span>
            </a>
          </li>
        </div>
      </ul>
    </nz-sider>
  </nz-layout>
</nz-layout>