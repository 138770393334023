import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { ConfigureLayoutComponent } from "./layouts/configure-layout/configure-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { NewUIComponent } from "./main/new-ui/new-ui.component";
import { AuthGuard } from "./_helpers/auth.guard";

const routes: Routes = [
  { path: "v1", component: NewUIComponent },
  {
    path: "login",
    component: FullLayoutComponent,
    loadChildren: () =>
      import("../app/main/auth/auth.module").then((m) => m.AuthModule),
    data: { full_screen: true },
  },
  {
    path: "dashboard",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/dashboards/dashboards.module").then(
        (m) => m.DashboardsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "facilities",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/facility/facility.module").then(
        (m) => m.FacilityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "organization",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/organizations/organizations.module").then(
        (m) => m.OrganizationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "products",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/product-info/product-info.module").then(
        (m) => m.ProductInfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "assets",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/asset-info/asset-info.module").then(
        (m) => m.AssetInfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketing",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/ticketing/ticketing.module").then(
        (m) => m.TicketingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "maintenance",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/maintenance-info/maintenance-info.module").then(
        (m) => m.MaintenanceInfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "data-upload",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/data-upload/data-upload.module").then(
        (m) => m.DataUploadModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "tracing",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/tracing/tracing.module").then((m) => m.TracingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "loss",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/loss-prevention/loss-prevention.module").then(
        (m) => m.LossPreventionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "environment",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/temperature-monitor/temperature-monitor.module").then(
        (m) => m.TemperatureMonitorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "configuration",
    component: ConfigureLayoutComponent,
    loadChildren: () =>
      import("../app/main/configartion/configartion.module").then(
        (m) => m.ConfigartionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    component: FullLayoutComponent,
    loadChildren: () =>
      import("../app/main/activation-config/activation-config.module").then(
        (m) => m.ActivationConfigModule
      ),
    data: { full_screen: true },
  },
  {
    path: "delete-records",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/delete-records/delete-records.module").then(
        (m) => m.DeleteRecordsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "asset-utilization",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/asset-utilization/asset-utilization.module").then(
        (m) => m.AssetUtilizationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "estimatedcapitalbg",
    component: CommonLayoutComponent,
    loadChildren: () =>
      import("../app/main/estimated-capital/estimated-capital.module").then(
        (m) => m.EstimatedCapitalModule
      ),
    canActivate: [AuthGuard],
  },
  { path: "*", redirectTo: "login" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
