import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IncidetTicketService {
  private incidentTckt : string;
  private menusLst : string;

  constructor() { }

  public setIncdntTcktData(incidentTckt):void {
    this.incidentTckt=incidentTckt;
 }
 public getIncdntTcktData():string {
  return this.incidentTckt;
}
public setmenusData(menusData):void {
  this.menusLst=menusData;
}
public getMenusData():string {
return this.menusLst;
}
}
