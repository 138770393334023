import { Component, OnInit } from '@angular/core';
import { IncidetTicketService } from 'src/app/providers/incidet-ticket.service';
import { LoginService } from 'src/app/providers/login.service';
import { ProfileService } from 'src/app/providers/profile.service';
import { ThemeService } from '../services/theme.service';
import  jwt_decode from 'jwt-decode';
import { UserService } from 'src/app/providers/user.service';
@Component({
  selector: 'app-config-header',
  templateUrl: './config-header.component.html',
  styleUrls: ['./config-header.component.scss']
})
export class ConfigHeaderComponent implements OnInit {

  isCollapsed: boolean;
  isExpand: boolean;
  loginId: any;
  token_decoded: any;
  userFullName: any;
  user: any;
  user_isConfg: any;
  userSno: any;
  constructor(
    private themeService: ThemeService,
    private loginApi: LoginService,
    private profileApi: ProfileService,
    private incdntTcktSrvc: IncidetTicketService,
    private usersApi: UserService,
  ) {
    this.userFullName = localStorage.getItem("userFullName");
    this.getProfile();
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isCollapsed) => (this.isCollapsed = isCollapsed)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.token_decoded = jwt_decode(localStorage.getItem("accesstoken"));
    this.loginId = this.token_decoded.login_id;
    this.userSno=this.token_decoded.user_sno;
    console.log(this.loginId);
    this.getUserDetails();
  }
  getUserDetails(){
   
    this.usersApi.getUserById(this.userSno).subscribe(result => {
      console.log(result)
      if (result.success) {
        this.user = result.data.user;
        console.log(this.user)
        this.user_isConfg  =  this.user.user_isConfg  
      } 
    }, err => {
      console.log(err);
     
    });
  }
  toggleFold() {
    this.isCollapsed = !this.isCollapsed;
    this.themeService.toggleFold(this.isCollapsed);
  }
  toggleExpand() {
    this.isCollapsed = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isCollapsed);
  }

  logout() {
    console.log("logout");
    this.loginApi.logout({}).subscribe(
      (result) => {
        console.log(result);
      },
      (err) => {
        console.log(err);
      }
    );
    sessionStorage.clear();
    localStorage.clear();
    this.loginApi.logoutUser();
    this.incdntTcktSrvc.setmenusData("");
  }
  getProfile() {
    this.profileApi.getProfile().subscribe(
      (result) => {
        if (result.success) {
          this.userFullName = result.profileData?.user_fullname;
          localStorage.setItem("userFullName", this.userFullName);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getUserFullName() {
    if (typeof localStorage["userFullName"] === "undefined") {
      return "";
    } else {
      return localStorage.getItem("userFullName");
    }
  }
}
