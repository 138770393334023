import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { map, filter, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';


@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService,private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let urlContcat = request.url.indexOf("http") > -1 ? request.url :  environment.END_POINT +'apiv1/' + request.url;
    let acessToken =localStorage.getItem('accesstoken');
    request = request.clone({ url: urlContcat });
    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    request = request.clone({ withCredentials: false });
    request = request.clone({ params:request.params})

    if (acessToken) {
      request = request.clone({ headers: request.headers.set('accesstoken', acessToken) });

      request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
      request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('accesstoken')) {
            localStorage.setItem('accesstoken', event.headers.get('accesstoken'));
          }
          if (event.status == 200)
            if (event.body.status == 404) {
            }
            if(event.body.errCode == '1004'){
              // window.alert(event.body.message)
              this.messageService.add({ key: 'myTopLeft', severity: 'error', summary: 'Response Message', detail: event.body.message });

            }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
         console.log('errr',error)
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          console.log(error)
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
          if(error.status === 403 || error.status === 401){
            localStorage.clear();
            window.location.href = "/login";
          }
          if(error.status === 600){
            errorMessage = `Invalid data . Please recheck the entered data`;
          }
          if(error.status === 0){
            errorMessage = 'Trouble in server.  Please contact administrator';
          }

        }
        return throwError(errorMessage);
        // let data = {};
        // data = {
        //   reason: error && error.error ? error.error.reason : '',
        //   status: error.status
        // };
        // return throwError(error);
      })

    );
  }
}
