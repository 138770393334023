import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { ThemeModule } from 'src/theme/theme.module';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { AuthModule } from './main/auth/auth.module';
import { ThemeService } from 'src/theme/services/theme.service';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { HttpInterceptorInterceptor } from './providers/http-interceptor.interceptor';
import { ApiService } from './providers/api.service';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ImageCropperModule } from 'ngx-image-cropper';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { EncrDecrService } from './providers/encrdecr.service';
import { ConfigureLayoutComponent } from './layouts/configure-layout/configure-layout.component';
import { StepsModule } from 'primeng/steps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    CommonLayoutComponent,
    ConfigureLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule,
    AuthModule,
    RouterModule,
    NzAvatarModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    NzSelectModule, 
    UiSwitchModule,
    ImageCropperModule,
    ToastModule,
    StepsModule,
    NgbModule,
  ],
  providers: [MessageService, { provide: NZ_I18N, useValue: en_US },
    ThemeService,ApiService,EncrDecrService,
  {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
