import { Component } from '@angular/core';
import { ThemeService } from 'src/theme/services/theme.service';
import { HostListener } from "@angular/core";
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
    styleUrls:['./common-layout.component.scss']
})

export class CommonLayoutComponent  {
    isCollapsed: boolean;
    scrHeight:any;
    scrWidth:any;  
    show:any;
    constructor(private themeService: ThemeService,private messageService: MessageService) { }
    ngOnInit(): void {
        this.getScreenSize();
        this.themeService.isMenuFoldedChanges.subscribe(isCollapsed => this.isCollapsed = isCollapsed);
        this.themeService.selectedHeaderColor.subscribe((res)=>{
             this.show = res;
        })
      }
      @HostListener('window:resize', ['$event'])
      getScreenSize(event?) {
            this.scrHeight = window.innerHeight;
            this.scrWidth = window.innerWidth;
            if(this.scrWidth < 768){
                this.themeService.toggleFold(true);
            }
            else{
                this.show = true;
                this.themeService.toggleFold(false);
            }
         
      }

      clear() {
        this.messageService.clear();
    }
}