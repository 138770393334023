import { Injectable } from "@angular/core";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Amplify, Auth } from "aws-amplify";
import { BehaviorSubject } from "rxjs";
import { congnitoConfig } from "../../aws_exports";

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;
  isAuth: boolean;
  constructor() {
    console.log(CognitoHostedUIIdentityProvider);
    Amplify.configure({
      Auth: congnitoConfig.cognito,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }
  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }
  public tokenGet(): Promise<any> {
    return Auth.currentSession().then(() => {
      this.authenticationSubject.next(true);
    });
  }
  public federatedSignIn() {
    // CognitoHostedUIIdentityProvider["provider"] = "aktivussotestazure";
    CognitoHostedUIIdentityProvider["provider"] = "aktivucyberarkdemo";
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider["provider"],
    });
  }
  public signOut(): Promise<any> {
    return Auth.signOut({ global: false })
      .then(() => {
        this.authenticationSubject.next(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  isLoggedIn(): boolean {
    this.isAuth = false;
    console.log("in loggi");
    Auth.currentSession()
      .then((res) => {
        console.log(res);
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        //You can print them to see the full objects
        if (jwt) {
          this.isAuth = true;
          console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
          localStorage.setItem("accesstoken", jwt);
          console.log(`myJwt: ${jwt}`);
          localStorage.setItem("me", "sravani");
        }
      })
      .catch((error) => {
        localStorage.setItem("error", error);
      });

    return this.isAuth;
  }
}
